<template>
  <!--
  <div class="d-flex align-items-center">
    <div class="card-body media align-items-center text-body">
      <i class="display-4 d-block text-primary" ></i>
      <span class="media-body d-block ml-3">
        <span class="text-big">
          {{ prefix }}
          <span class="font-weight-bolder" v-if="loading"><i class="fa fa-cog fa-spin text-muted"></i></span>
          <span class="font-weight-bolder" v-if="!loading">{{ value }}</span>
          {{ suffix }}
        </span>
        <br />
        <small class="text-muted"><i class="fa fa-filter text-light" v-if="textoFiltrado" v-b-tooltip.hover :title="textoFiltrado"></i> {{ title }}</small>
      </span>
    </div>
  </div>
-->
  <div class="card" style="">
    <div class="card-body">
      <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
        <i :class="iconClass"></i>
        {{ prefix }}
        <span class="font-weight-bolder" v-if="loading"><i class="fa fa-cog fa-spin text-muted"></i></span>
        <span class="font-weight-bolder" v-if="!loading">{{ value }}</span>
        {{ suffix }}
      </span>
      <span class="font-weight-bold text-muted font-size-sm">{{ title }} <i class="fa fa-filter text-light" v-if="textoFiltrado" v-b-tooltip.hover :title="textoFiltrado"></i></span>
    </div>
  </div>
</template>

<style></style>

<script>
import Vue from "vue";
import moment from "moment";

import { Chart } from "highcharts-vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    iconClass: {
      type: String,
      default: "lnr lnr-chart-bars" /*lnr lnr-checkmark-circle*/,
    },
    fixedValue: {
      type: String,
    },
    section: {
      type: String,
    },
    code: {
      type: String,
    },
    column: {
      type: String,
    },
    filaTieneTitulo: {
      type: Boolean,
      default: false,
    },
    columnIndex: {
      type: String,
    },
    rowIndex: {
      type: String,
    },
    pivotReportData: { type: Boolean, default: false },
    //updateTriggers
    fechaInicio: null,
    fechaFin: null,
    departamentoId: Number,
    paisId: Number,
  },
  components: {},
  data: () => ({
    getDataHandler: null /* function(){}*/,
    //section: "DASHBOARD",
    //code: "RANGO_EDAD",
    //reportFormat: "SERIE-CATEGORY-ARRAY",
    //serieFormat: "SERIE-SEPARATED",
    //serieTitle: "Rango Etario",
    //chartType: "pie",
    value: null,
    chartOptions: {},
    loading: true,
    rawData: [],
    //updateTriggers: ["fechaInicio", "fechaFin", "departamentoId", "paisId"],
  }),
  computed: {
    textoFiltrado: function() {
      var filtros = [];

      if (this.fechaInicio != null) filtros.push("Mayor a fecha");
      if (this.fechaFin != null) filtros.push("Menor a fecha");
      if (this.departamentoId != null) filtros.push("Región");
      if (this.paisId != null) filtros.push("País");

      return filtros.join(", ");
    },
  },
  methods: {
    getGenericReportData: function(extraParams) {
      var params = {
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        departamentoId: this.departamentoId,
        paisId: this.paisId,
      };

      var reportFormat = this.pivotReportData ? "SERIE-CATEGORY-PIVOT-ARRAY" : "SERIE-CATEGORY-ARRAY";

      var model = {
        seccion: this.section,
        codigo: this.code,
        formato: reportFormat,
        filaTieneTitulo: this.filaTieneTitulo,
        parametros: { ...params, ...extraParams },
      };

      this.loading = true;

      this.$http.post(this.apiRoute("ReportData", "GetJson"), model).then(function(response) {
        if (!response.body.error) {
          var columnIndex = -1;
          var columnValue = "N/A";

          if (response.body.data.filas.length > 0 && response.body.data.columnas.length > 0) {
            if (this.column) {
              for (let c = 0; c < response.body.data.columnas.length; c++) {
                let columna = response.body.data.columnas[c];
                if (columna.toLowerCase() == this.column.toLowerCase()) {
                  columnIndex = c;
                  break;
                }
              }
            } else {
              columnIndex = this.columnIndex || 0;
            }

            if (columnIndex == -1) {
              console.log("No se encontró la columna: " + this.column);
            } else {
              var rowIndex = this.rowIndex || 0;
              columnValue = response.body.data.filas[rowIndex].valores[columnIndex];
            }
          }

          /*
          if (!this.pivotTable) {
            this.rawData = response.body.data;
          } else {
            var original = response.body.data;
            var pivoted = {
              columnas: [],
              filas: [],
            };

            for (let c = 0; c < response.body.data.columnas.length; c++) {
              let columna = response.body.data.columnas[c];
              let fila = {
                titulo: columna,
                valores: [],
              };

              pivoted.filas.push(fila);
            }

            for (let i = 0; i < response.body.data.filas.length; i++) {
              let item = response.body.data.filas[i];
              pivoted.columnas.push(item.titulo);
              for (let c = 0; c < response.body.data.columnas.length; c++) {
                pivoted.filas[c].valores.push(item.valores[c]);
              }
            }

            this.rawData = pivoted;
          }*/

          this.value = columnValue;
          this.rawData = response.body.data;
          this.loading = false;
        }
      });
    },
    initReporte() {
      this.loading = true;
      if (this.fixedValue != null && this.fixedValue != undefined) {
        this.strategy = "fixed-value";
        this.value = this.fixedValue;
        this.loading = false;
      } else if (this.section != null && this.code != null) {
        this.strategy = "report-data";
        this.getGenericReportData();
      } else {
        this.strategy = "blank";
        this.value = null;
      }
    },
  },
  watch: {
    departamentoId: function(newValue, oldValue) {
      this.initReporte();
    },
    paisId: function(newValue, oldValue) {
      this.initReporte();
    },
    fechaInicio: function(newValue, oldValue) {
      this.initReporte();
    },
    fechaFin: function(newValue, oldValue) {
      this.initReporte();
    },
  },
  mounted() {
    this.initReporte();
  },
};
</script>
