<template>
  <div class="">
    <!--
    <b-card no-body class="w-100 mb-4">
      <div class="card-header with-elements">
        <span class="card-header-title mr-2 text-big">{{ title }}</span>

        <div class="card-header-elements ml-md-auto">
          <i class="fa fa-fw fa-filter text-light" v-if="textoFiltrado" v-b-tooltip.hover :title="textoFiltrado"></i>
          <button type="button" v-if="chartType" class="btn icon-btn btn-sm btn-default" @click="view = 'chart'" :class="[view == 'chart' ? 'bg-light' : '']">
            <span class="fa fa-chart-pie" v-if="chartType == 'pie'"></span>
            <span class="fa fa-chart-bar" v-else></span>
          </button>
          <button type="button" class="btn icon-btn btn-sm btn-default " v-show="view != 'table'" @click="view = 'table'" :class="[view == 'table' ? 'bg-light' : '']">
            <span class="fa fa-table"></span>
          </button>
          <button type="button" class="btn icon-btn btn-sm btn-default " v-show="view == 'table'" @click="copyHtmlToClipboard('tbl-' + guid, 'btn-copy-tbl-' + guid, defaultCopiedIndicator)" :id="'btn-copy-tbl-' + guid">
            <span class="fa fa-copy"></span>
          </button>
        </div>
      </div>
      <div v-if="loading" :style="{ height: height + 'px' }" style="text-align:center; justify-content: center; align-items: center; " class="d-flex">
        <span class="text-big text-muted"> <i class="fa fa-cog fa-spin"></i> Cargando datos... </span>
      </div>
      <div v-else-if="error" :style="{ height: height + 'px' }" class="d-flex p-2" style="overflow: scroll;">
        <span class="text-small text-muted">
          <div class="text-danger">Ha ocurrido un error al generar los datos</div>
          {{ error }}
        </span>
      </div>
      <div v-else-if="!loading">
        <highcharts :style="{ height: height + 'px' }" v-if="view == 'chart'" :options="chartOptions" ref="reportChart"></highcharts>
        <div class="table-responsive mb-0" v-if="view == 'table'" :style="{ height: allowTableExceedHeight ? null : height + 'px' }">
          <table class="table table-sm" :id="'tbl-' + guid" :class="[forzarFilaUnaLinea ? 'table-cell-ellipsis' : '']">
            <tr>
              <th v-if="filaTieneTitulo"><span></span></th>
              <th class="text-center" v-for="(columna, index) in rawData.columnas" :key="index">
                <span>{{ columna }}</span>
              </th>
            </tr>
            <tr v-for="(fila, index) in rawData.filas" :key="index">
              <th class="text-right" v-if="filaTieneTitulo">
                <span>{{ fila.titulo }}</span>
              </th>
              <td class="text-center" v-for="(valor, indexVal) in fila.valores" :key="indexVal">
                <span>{{ valor }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-card>
    -->

    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title font-weight-bolder">{{ title }}</h3>
        <div class="card-toolbar">
          <i class="fa fa-fw fa-filter text-muted" v-if="textoFiltrado" v-b-tooltip.hover :title="textoFiltrado"></i>
          <button type="button" v-if="chartType" class="btn icon-btn btn-sm btn-default ml-1" @click="view = 'chart'" :class="[view == 'chart' ? 'bg-light' : '']">
            <span class="fa fa-chart-pie" v-if="chartType == 'pie'"></span>
            <span class="fa fa-chart-bar" v-else></span>
          </button>
          <button type="button" class="btn icon-btn btn-sm btn-default ml-1" v-show="view != 'table'" @click="view = 'table'" :class="[view == 'table' ? 'bg-light' : '']">
            <span class="fa fa-table"></span>
          </button>
          <button type="button" class="btn icon-btn btn-sm btn-default ml-1" v-show="view == 'table'" @click="copyHtmlToClipboard('tbl-' + guid, 'btn-copy-tbl-' + guid, defaultCopiedIndicator)" :id="'btn-copy-tbl-' + guid">
            <span class="fa fa-copy"></span>
          </button>
        </div>
      </div>
      <div class="card-body d-flex flex-column p-0">
        <div class="flex-grow-1" style="position: relative;">
          <div v-if="loading" :style="{ height: height + 'px' }" style="text-align:center; justify-content: center; align-items: center; " class="d-flex">
            <span class="text-big text-muted"> <i class="fa fa-cog fa-spin"></i> Cargando datos... </span>
          </div>
          <div v-else-if="error" :style="{ height: height + 'px' }" class="d-flex p-2" style="overflow: scroll;">
            <span class="text-small text-muted">
              <div class="text-danger">Ha ocurrido un error al generar los datos</div>
              {{ error }}
            </span>
          </div>
          <div v-else-if="!loading">
            <highcharts :style="{ height: height + 'px' }" v-if="view == 'chart'" :options="chartOptions" ref="reportChart"></highcharts>
            <div class="table-responsive mb-0" v-if="view == 'table'" :style="{ height: allowTableExceedHeight ? null : height + 'px' }">
              <table class="table table-sm" :id="'tbl-' + guid" :class="[forzarFilaUnaLinea ? 'table-cell-ellipsis' : '']">
                <tr>
                  <th v-if="filaTieneTitulo"><span></span></th>
                  <th class="text-center" v-for="(columna, index) in rawData.columnas" :key="index">
                    <span>{{ columna }}</span>
                  </th>
                </tr>
                <tr v-for="(fila, index) in rawData.filas" :key="index">
                  <th class="text-right" v-if="filaTieneTitulo">
                    <span>{{ fila.titulo }}</span>
                  </th>
                  <td class="text-center" v-for="(valor, indexVal) in fila.valores" :key="indexVal">
                    <span>{{ valor }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card.card-custom > .card-header .card-title {
  margin-right: 85px;
}

.card-toolbar {
  position: absolute;
  right: 10px;
}
</style>

<script>
import Vue from "vue";
import moment from "moment";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";

exportingInit(Highcharts);

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    height: { default: "300" },
    defaultView: {
      type: String,
      validator: function(value) {
        if (!value) return true;
        return ["table", "chart"].indexOf(value) !== -1;
      },
    },
    colores: {},
    section: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    filaTieneTitulo: {
      type: Boolean,
      default: true,
    },
    forzarFilaUnaLinea: {
      type: Boolean,
      default: true,
    },
    allowTableExceedHeight: { type: Boolean, default: true },
    pivotTable: { type: Boolean, default: false },
    pivotReportData: { type: Boolean, default: false },
    chartType: {
      validator: function(value) {
        if (!value) return true;
        return ["pie", "column", "bar"].indexOf(value) !== -1;
      },
    },
    //updateTriggers
    fechaInicio: null,
    fechaFin: null,
    procesoElectoralId: Number,
    localVotacionId: Number,
    regionId: Number,
    subRegionId: Number,
  },
  components: {
    highcharts: Chart,
  },
  data: () => ({
    guid: "",
    getDataHandler: null /* function(){}*/,
    //section: "DASHBOARD",
    //code: "RANGO_EDAD",
    //reportFormat: "SERIE-CATEGORY-ARRAY",
    //serieFormat: "SERIE-SEPARATED",
    //serieTitle: "Rango Etario",
    //chartType: "pie",
    chartOptions: {},
    loading: true,
    rawData: [],
    view: "table",
    error: "",
    //updateTriggers: ["fechaInicio", "fechaFin", "departamentoId", "paisId"],
  }),
  computed: {
    textoFiltrado: function() {
      var filtros = [];

      if (this.localVotacionId != null) filtros.push("Local de Votación");
      if (this.regionId != null) filtros.push("Región");
      if (this.subRegionId != null) filtros.push("Sub región");

      return filtros.join(", ");
    },
  },
  methods: {
    clickTest: function() {
      this.dataTest = moment();
    },

    getColors: function(num) {
      var gris = "#d4d6dd";
      var rojo = "#f64e60";
      var ambar = "#ffa800";
      var verde = "#1bc5bd";
      var azul = "#3699ff";
      var morado = "#8950fc";

      if (this.colores == "VERDE-ROJO-GRIS") return [verde, rojo, gris];
      if (this.colores == "ROJO-VERDE-GRIS") return [rojo, verde, gris];
      if (this.colores == "GRIS-VERDE-ROJO") return [gris, verde, rojo];
      if (this.colores == "GRIS-ROJO-VERDE") return [gris, rojo, verde];

      if (this.colores == "SEMAFORO") return [verde, ambar, rojo, gris];
      if (this.colores == "SEMAFORO-INV") return [rojo, ambar, verde, gris];

      return ["#058DC7", "#50B432", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"];

      /*var colors = [];
      var base = Highcharts.getOptions().colors[0];

      for (var i = 0; i < 10; i += 1) {
        colors.push(
          Highcharts.color(base)
            .brighten((i - 3) / 7)
            .get()
        );
      }
      return colors;*/
    },

    getGenericChartOptions: function() {
      var colors = this.getColors(null);

      if (this.chartType == "bar")
        return {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "bar",
          },
          colors: colors,
          title: {
            text: "",
          },
          xAxis: {
            categories: this.categories,
            title: {
              text: null,
            },
          },
          yAxis: {
            title: {
              text: "",
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y}</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: this.series,
        };

      if (this.chartType == "column")
        return {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "column",
          },
          title: {
            text: "",
          },
          colors: colors,
          xAxis: {
            categories: this.categories,
            title: {
              text: "",
            },
          },
          yAxis: {
            title: {
              text: "",
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y}</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: this.series,
        };

      if (this.chartType == "pie")
        return {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
          },
          title: {
            text: "",
          },

          credits: {
            enabled: false,
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              colors: colors,
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          series: this.series,
        };
    },

    getGenericReportData: function(reportKey, reportConfig, extraParams) {
      var params = {
        procesoElectoralId: this.procesoElectoralId,
        localVotacionId: this.localVotacionId,
        regionId: this.regionId,
        subRegionId: this.subRegionId,
      };

      var reportFormat = this.pivotReportData ? "SERIE-CATEGORY-PIVOT-ARRAY" : "SERIE-CATEGORY-ARRAY";

      var model = {
        seccion: this.section,
        codigo: this.code,
        formato: reportFormat,
        filaTieneTitulo: this.filaTieneTitulo,
        parametros: { ...params, ...extraParams },
      };

      this.loading = true;

      this.$http.post(this.apiRoute("ReportData", "GetJson"), model).then(
        function(response) {
          if (response.body.error) {
            this.error = response.body.message;
            this.loading = false;
          } else if (!response.body.error) {
            if (this.chartType) {
              var serieFormat = "";
              switch (this.chartType) {
                case "bar":
                  serieFormat = "SERIE-CATEGORY-DATA-ARRAY";
                  break;
                case "column":
                  serieFormat = "SERIE-CATEGORY-DATA-ARRAY";
                  break;
                case "pie":
                  serieFormat = "SERIE-SEPARATED";
                  break;
              }

              //utilizado para bar y column. Evaluar cambiar el condicional por tipo de gráfico
              if (serieFormat == "SERIE-CATEGORY-DATA-ARRAY") {
                let formattedData = [];
                for (let i = 0; i < response.body.data.filas.length; i++) {
                  let item = response.body.data.filas[i];
                  formattedData.push({ name: item.titulo, data: item.valores });
                }

                this.categories = response.body.data.columnas;
                this.series = formattedData;
              }

              //utilizado para pie. Evaluar cambiar el condicional por tipo de gráfico
              if (serieFormat == "SERIE-SEPARATED") {
                let formattedSeries = [];

                for (let c = 0; c < response.body.data.columnas.length; c++) {
                  let columna = response.body.data.columnas[c];
                  let serie = {
                    name: columna,
                    //colorByPoint: true,
                    data: [],
                  };

                  for (let i = 0; i < response.body.data.filas.length; i++) {
                    let item = response.body.data.filas[i];
                    serie.data.push({ name: item.titulo, y: item.valores[c] });
                  }

                  formattedSeries.push(serie);
                }
                //categories = response.body.data.columnas;
                this.series = formattedSeries;
              }

              this.chartOptions = this.getGenericChartOptions();
            }

            if (!this.pivotTable) {
              this.rawData = response.body.data;
            } else {
              var original = response.body.data;
              var pivoted = {
                columnas: [],
                filas: [],
              };

              for (let c = 0; c < response.body.data.columnas.length; c++) {
                let columna = response.body.data.columnas[c];
                let fila = {
                  titulo: columna,
                  valores: [],
                };

                pivoted.filas.push(fila);
              }

              for (let i = 0; i < response.body.data.filas.length; i++) {
                let item = response.body.data.filas[i];
                pivoted.columnas.push(item.titulo);
                for (let c = 0; c < response.body.data.columnas.length; c++) {
                  pivoted.filas[c].valores.push(item.valores[c]);
                }
              }

              this.rawData = pivoted;
            }

            this.loading = false;
          }
        },
        function(err) {
          this.error = err;
        }
      );
    },
  },
  watch: {
    localVotacionId: function(newValue, oldValue) {
      this.getGenericReportData();
    },
    procesoElectoralId: function(newValue, oldValue) {
      this.getGenericReportData();
    },
    regionId: function(newValue, oldValue) {
      this.getGenericReportData();
    },
    subRegionId: function(newValue, oldValue) {
      this.getGenericReportData();
    },
  },
  mounted() {
    this.guid = this.getGuid();
    if (this.defaultView) {
      this.view = this.defaultView;
    } else if (this.chartType) {
      this.view = "chart";
    }

    this.getGenericReportData();
  },
};
</script>
